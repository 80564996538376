window.EE = window.EE || {};

(function($, window, EE) {
	EE.Utils = {
		MobileView: {
			Any: false,
			Phone: false,
			Tablet: false
		}
	};

	var _this = EE.Utils;

	// Detect type of device: Desktop, Tablet or Mobile
	(function() {
		var userAgentRegEx = /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|NokiaBrowser|Tablet|Mobi|Kindle|Silk|BB10/i;
		_this.MobileView.Any = !!navigator.userAgent.match(userAgentRegEx);
		var $html = $('html');

		// Check for user agent
		(function(a, b) {
			if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {  // jshint ignore:line
				b();
			}
		})(navigator.userAgent || navigator.vendor || window.opera, function() {
			_this.MobileView.Phone = true;
		});

		if (_this.MobileView.Any && !_this.MobileView.Phone) {
			_this.MobileView.Tablet = true;
		}

		if (_this.MobileView.Tablet) {
			$html.addClass('tablet-view');
		}

		if (_this.MobileView.Phone) {
			$html.addClass('phone-view');
		}

		if (_this.MobileView.Any) {
			$html.addClass('any-mobile-view');
		}
	})();

	// Utility to find the document width is in handheld size
	_this.isHandheld = function() {
		return $(document).innerWidth() < 480;
	};

	// Remove suffixes
	_this.cutString = function(string, separator) {
		var str = string;
		var endPosition = str.search(separator);

		if (endPosition > 0) {
			str = str.slice(0, endPosition);
		}

		return str;
	};

	// the method was added due to the
	// DEL-12403 Extra space at the bottom of page, when card is selected
	_this.recalculateSidenavHeight = function() {
		// var newHeight = $('body').height() - $('.ee-masthead').height();

		// $('#mainNav').height(newHeight);
		// $('#navMaskInner').height(newHeight);
		// $('.scrollNav').height(newHeight - 140);
	};


	_this.hasLocalStorage = function() {
		var test = 'testLocalStorage';
		try {
			window.localStorage.setItem(test, test);
			window.localStorage.removeItem(test);
			return true;
		} catch (e) {
			return false;
		}
	};

	_this.isEnterKeyPressed = function(event) {
		var keycode = (event.keyCode ? event.keyCode : event.which);
		return keycode === 13;
	};

	_this.throttle = function(func, ms) {
		var isThrottled = false;
		var savedArgs;
		var savedThis;

		function wrapper() {
			if (isThrottled) {
				savedArgs = arguments;
				savedThis = this;
				return;
			}

			func.apply(this, arguments);

			isThrottled = true;

			setTimeout(function() {
				isThrottled = false;
				if (savedArgs) {
					wrapper.apply(savedThis, savedArgs);
					savedArgs = savedThis = null;
				}
			}, ms);
		}

		return wrapper;
	};

	_this.debounce = function(func, wait, immediate) {
		var timeout;
		return function() {
			var context = this;
			var args = arguments;
			var later = function() {
				timeout = null;
				if (!immediate) {
					func.apply(context, args);
				}
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) {
				func.apply(context, args);
			}
		};
	};
})($, window, EE);
